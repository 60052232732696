<template>
    <v-container id="portfolios">
        <h2 class="text-center mb-7">
            <span class="d-block primary--text mb-2" data-aos="fade-up">Our Works</span>
            <p data-aos="fade-up" data-aos-delay="150">Recently Added Portfolios</p>
        </h2>
        <div class="text-center" data-aos="fade-up" data-aos-delay="200">
            <h3 class="mb-3 mb-md-8 rounded-pill primary d-inline-block px-10 py-2 white--text">
                WEB
            </h3>
        </div>
        <v-row>
            <WorkCard
                v-for="(work, i) in works"
                :key="work.id"
                :work="work"
                data-aos="fade-up"
                :data-aos-delay="200 + 100 * i"
                @onclick="openDialog(work)"
                @onimgclick="viewImage(work)"
            />
        </v-row>

        <v-dialog v-if="open" scrollable v-model="open" width="900px">
            <v-card class="rounded-xl">
                <v-toolbar elevation="0">
                    <v-toolbar-title class="title text-truncate pl-3">{{ dialogData.title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon class="grey--text white" text @click="open = false">
                        <v-icon> mdi-close </v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider class="grey lighten-4"></v-divider>
                <v-card-text class="pt-3">
                    <v-row>
                        <v-col
                            flat
                            v-for="(d, idx) in dialogData.details"
                            :key="d.id"
                            style="min-width:300px"
                            :class="$vuetify.breakpoint.xs ? 'py-4' : 'py-6'"
                        >
                            <div class="detail-img">
                                <v-img
                                    height="250"
                                    :src="require(`@/assets/${d.img}.png`)"
                                    position="left top"
                                    @click="viewImages(dialogData.details, idx)"
                                    style="cursor: pointer;"
                                />
                            </div>
                            <div class="subtitle-1 grey--text text--darken-3 font-weight-medium text-center mt-3 mb-1">
                                {{ d.title }}
                            </div>
                            <div v-html="d.description" class="text-center"></div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <div class="text-center pt-md-10" data-aos="fade-up" data-aos-delay="300">
            <h3 class="mb-3 mt-10 mb-md-8 mt-md-16  rounded-pill primary d-inline-block px-10 py-2 white--text">
                APPS
            </h3>
        </div>
        <v-row>
            <AppCard
                v-for="(app, i) in apps"
                :key="app.id"
                :app="app"
                data-aos="fade-up"
                :data-aos-delay="300 + 100 * i"
                @onclick="openDialog(app)"
                @onimgclick="viewImage(app)"
            />
        </v-row>

        <vue-easy-lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="visible = false"
        ></vue-easy-lightbox>
    </v-container>
</template>

<style scoped lang="scss">
h2 {
    font-family: 'Prompt', sans-serif;
    font-size: 1.85rem;
    span {
        font-size: 1.18rem;
        font-weight: 400;
    }
    p {
        line-height: 1.3;
    }
}
.v-dialog .v-card {
    background: #f8f8f8;
}
.v-dialog__content::v-deep .v-dialog {
    border-radius: 24px !important;
    .title {
        font-family: 'S-CoreDream' !important;
    }
}
.detail-img .v-responsive {
    box-shadow: 0 5px 20px rgba(#000, 0.15) !important;
}
.v-image {
    border-radius: 16px !important;
}
</style>

<script>
import { mapGetters } from 'vuex';

import WorkCard from './WorkCard.vue';
import AppCard from './AppCard.vue';

export default {
    components: { WorkCard, AppCard },
    data: () => ({
        open: false,
        dialogData: null,
        visible: false,
        imgs: [],
        index: 0,
    }),
    computed: {
        ...mapGetters(['works', 'apps']),
    },
    methods: {
        openDialog(work) {
            this.open = true;
            this.dialogData = work;
        },

        viewImage(work) {
            this.visible = true;
            this.imgs = [require(`@/assets/${work.img}.png`)];
            this.index = 0;
        },

        viewImages(details, idx) {
            let imgs = details.map((d) => require(`@/assets/${d.img}.png`));
            for (let i = 0; i < idx; i++) {
                const img = imgs.shift();
                imgs.push(img);
            }

            this.visible = true;
            this.imgs = imgs;
            this.index = 0;
        },
    },
};
</script>
