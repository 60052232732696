<template>
    <v-col data-aos="fade-up">
        <v-hover v-slot="{ hover }">
            <v-card :class="{ 'on-hover': hover }" class="mx-auto rounded-xl pa-5">
                <v-img
                    aspect-ratio="1"
                    height="250"
                    min-width="250"
                    :src="require(`@/assets/${app.img}.png`)"
                    position="left top"
                    @click="$emit('onimgclick')"
                    style="cursor:pointer"
                />
                <div class="d-flex justify-space-between align-center mt-5">
                    <v-card-subtitle class="rounded-pill py-1 px-4" v-html="app.type"></v-card-subtitle>
                    <div>
                        <v-btn v-if="app.link" icon :href="app.link" target="_blank">
                            <img src="@/assets/googleplay_logo.svg" width="16" />
                        </v-btn>
                        <v-btn v-if="app.link2" icon :href="app.link2" target="_blank" class="ml-2">
                            <v-icon> mdi-apple </v-icon>
                        </v-btn>
                        <v-btn icon @click="$emit('onclick')" v-if="app.details">
                            <v-icon> mdi-plus-circle </v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-title class="px-1 pb-2 d-flex flex-column align-start">
                    <div class="year">
                        {{ app.year }}
                    </div>
                    <div style="line-height:1.4" class="my-1">{{ app.title }}</div>
                </v-card-title>
            </v-card>
        </v-hover>
    </v-col>
</template>

<script>
export default {
    name: 'AppCard',
    props: {
        app: {
            type: Object,
            retuired: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.v-card {
    transition: 0.5s !important;
    box-shadow: none !important;
    position: relative;
    z-index: 10;
    @media #{map-get($display-breakpoints, 'xs-only')} {
        box-shadow: 0 0 15px rgba(#000, 0.1) !important;
    }
    .v-card__subtitle {
        background-color: var(--v-secondary-base);
        color: #fff !important;
        font-family: 'S-CoreDream' !important;
        &::v-deep i {
            font-size: 0.8rem;
            font-style: normal;
            vertical-align: 0.05rem;
            font-weight: 100 !important;
            display: inline-block;
            margin: 0 3px;
        }
    }
    .v-btn {
        background: rgba(#000, 0.04);
        .v-icon {
            color: rgba(#000, 0.2);
        }
    }
    .year {
        font-weight: 600;
        font-size: 0.75rem;
        letter-spacing: 1px;
        line-height: 1.5rem;
        color: rgba(#000, 0.4);
    }
    .v-card__title {
        font-size: 1.125rem;
        letter-spacing: 0;
        font-weight: 700 !important;
        font-family: 'S-CoreDream' !important;
    }
}
.v-card.on-hover {
    transform: translate(-1%, -1%);
    background-color: var(--v-primary-base);
    box-shadow: 10px 10px 30px rgba(#000, 0.2) !important;

    .v-card__subtitle {
        background-color: #fff;
        color: var(--v-primary-base) !important;
        font-weight: 500;
    }
    .year {
        font-weight: 500;
        color: rgba(#fff, 0.5);
    }
    .v-card__title {
        color: #fff;
        font-weight: 500 !important;
    }
    .v-card__text {
        color: #fff;
        font-weight: 300;
    }
    .v-btn {
        background: rgba(#fff, 0.2);
        .v-icon {
            color: #fff;
        }
    }
}
.v-image {
    border-radius: 16px !important;
}
</style>
