import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        works: [
            {
                id: 1,
                year: '2020.07 - 10',
                title: 'LINC+ 종합성과관리 시스템 개발',
                img: '2020/lincplus_01',
                type: '분석<i>/</i>설계<i>/</i>개발',
                description: 'LINC+ 선정대학 정량/정성 평가, 평가위원풀 관리 및 추출, 업무지원 시스템, CMSLINC+ 선정대학 정량/정성 평가, 평가위원풀 관리 및 추출, 업무지원 시스템, CMS',
                link: 'https://lincthree.nrf.re.kr/',
                details: [
                    {
                        id: 1,
                        img: '2020/lincplus_02',
                        title: '평가위원 평가지원',
                        description: '대학이 제출한 자료로 평가위원들이 검토후 정량/정성 평가를 하도록 지원<br/> 보고관리/평가현황',
                    },
                    {
                        id: 2,
                        img: '2020/lincplus_03',
                        title: '대학 평가자료 제출',
                        description: '100여개 선정대학이 접속 후 평가를 위한 자료 업로드<br/> LINC+ 홈페이지에 게재될 대학정보 편집<br/>사업관리 / 예산변경신청 / 성과관리 / 행사정보',
                    },
                    {
                        id: 3,
                        img: '2020/lincplus_04',
                        title: '연구재단 실무자 업무지원 및 관리',
                        description: '웹사이트 컨텐츠 관리및 평가를 위한 기반 업무처리<br/>사업관리 / 평가관리 / 평가위원 관리 / 회원관리 / 홈페이지 관리',
                    },
                    {
                        id: 4,
                        img: '2020/lincplus_05',
                        title: '대학계정관리',
                        description: '',
                    },
                    {
                        id: 5,
                        img: '2020/lincplus_06',
                        title: '평가위원 평가관리',
                        description: '',
                    },
                ],
            },
            {
                id: 2,
                year: '2020.08 - 10',
                title: 'KISTI 머신러닝 데이터구축사업',
                img: '2020/kistimldms_01',
                type: '설계<i>/</i>개발',
                description: 'KISTI 머신러닝 데이터구축 사업 업무파트 개발. 작업자 일일보고 작성기능. 관리자 관리기능(일일보고, 목표대비 실적현황, 근태관리, 근태현황)',
                details: [
                    {
                        id: 1,
                        img: '2020/kistimldms_02',
                        title: '관리자 관리기능',
                        description: '목표대비 실적현황',
                    },
                    {
                        id: 2,
                        img: '2020/kistimldms_03',
                        title: '관리자 관리기능',
                        description: '작업자 근태관리',
                    },
                    {
                        id: 3,
                        img: '2020/kistimldms_04',
                        title: '관리자 관리기능',
                        description: '작업자 근태관련 경고현황',
                    },
                    {
                        id: 4,
                        img: '2020/kistimldms_05',
                        title: '작업자',
                        description: '일일보고 작성기능',
                    },
                ],
            },
            {
                id: 4,
                year: '2020.10 - 11',
                title: '중소벤처기업진흥공단 DW프로젝트',
                img: '2020/dw_01',
                type: '분석<i>/</i>설계<i>/</i>개발',
                description: 'ETL 솔루션(TeraStream)을 활용하여 각 분야 데이터를 추출, 변환 및 적재 후 사용자 친화적인 GUI 및 엑셀파일 형식으로 레포트 출력.',
            },
            {
                id: 5,
                year: '2020.11',
                title: '기초과학연구원 자원신청사이트 개발지원',
                img: '2020/ibs_01',
                type: '설계<i>/</i>개발<i>/</i>디자인',
                description: '기초과학연구원 슈퍼컴퓨터의 Cluster, Data Storage, Gitlab 자원에 대한 사용신청 사이트. 관리자가 신청된 접수건에 대해 처리 및 자원ID 맵핑 처리',
                details: [
                    {
                        id: 1,
                        img: '2020/ibs_02',
                        title: '사용현황 페이지',
                        description: '사용자가 신청한 자원의 사용현황을 API 호출을 통해 보여줍니다.',
                    },
                    {
                        id: 2,
                        img: '2020/ibs_03',
                        title: '관리자 페이지',
                        description: '사용자의 신청에 대한 처리, 자원ID 맵핑 및 기타 관리 기능',
                    },
                    {
                        id: 3,
                        img: '2020/ibs_04',
                        title: '반응형 사용자 사이트',
                        description: '반응형으로 모바일 버전 사이트 제공',
                    },
                    {
                        id: 4,
                        img: '2020/ibs_05',
                        title: '반응형 관리자 사이트',
                        description: '반응형으로 관리자를 위한 모바일 버전 사이트 제공',
                    },
                ],
            },
            {
                id: 6,
                year: '2021.01 - 03',
                title: '지하매설물 관리 시스템',
                img: '2021/bauger_01',
                type: '개발<i>/</i>디자인',
                description: 'Backend API : Spring Boot / Frontend : Vue.js & Vuetify / 반응형 사이트',
                details: [
                    {
                        id: 1,
                        img: '2021/bauger_01',
                        title: '메인 페이지',
                        description: '',
                    },
                    {
                        id: 2,
                        img: '2021/bauger_02',
                        title: '메인 페이지 - 모바일',
                        description: '',
                    },
                    {
                        id: 3,
                        img: '2021/bauger_03',
                        title: '상세 페이지',
                        description: '',
                    },
                    {
                        id: 4,
                        img: '2021/bauger_04',
                        title: '상세 페이지 - 모바일',
                        description: '',
                    },
                ],
            },
            {
                id: 7,
                year: '2021.03 - 06',
                title: '2021 LINC+ 육성사업 정성/정량 평가 개발',
                img: '2020/lincplus_01',
                type: '분석<i>/</i>설계<i>/</i>개발',
                description: '2021년도 LINC+ 육성사업 선정 대학의 정성/정량 평가 시스템 개발 및 기존 시스템 개선',
                link: 'https://lincthree.nrf.re.kr/',
            },
            {
                id: 8,
                year: '2021.07 - 10',
                title: '대전문화재단 생활문화포털',
                img: '2021/culture_01',
                type: '분석<i>/</i>설계<i>/</i>개발<i>/</i>디자인',
                description: '대전문화재단 생활문화 지원사업 공모신청 및 관리 사이트, Backend : SpringBoot, Frontend : Vue/Quasar',
                link: 'https://artlife.dcaf.or.kr',
                details: [
                    {
                        id: 1,
                        img: '2021/culture_01',
                        title: '메인 페이지',
                        description: '',
                    },
                    {
                        id: 2,
                        img: '2021/culture_02',
                        title: '메인 페이지 - 소개',
                        description: '',
                    },
                    {
                        id: 3,
                        img: '2021/culture_03',
                        title: '상세 페이지 - 사업안내',
                        description: '',
                    },
                    {
                        id: 4,
                        img: '2021/culture_04',
                        title: '상세 페이지 - 공모신청',
                        description: '',
                    },
                    {
                        id: 5,
                        img: '2021/culture_05',
                        title: '상세 페이지 - 공간',
                        description: '',
                    },
                    {
                        id: 6,
                        img: '2021/culture_06',
                        title: '상세 페이지 - 공지사항',
                        description: '',
                    },
                    {
                        id: 7,
                        img: '2021/culture_07',
                        title: '상세 페이지 - 공간등록',
                        description: '',
                    },
                    {
                        id: 8,
                        img: '2021/culture_08',
                        title: '관리자 페이지',
                        description: '',
                    },
                ],
            },
            {
                id: 9,
                year: '2021.09 - 10',
                title: 'ETRI 영상분석 GUI',
                img: '2021/etri',
                type: '개발',
                description: '사용자 프로파일링 및 영상DB 관리 GUI 시스템, Python GUI Application(eel with vue)',
            },
            {
                id: 10,
                year: '2021.10 - 2022.10',
                title: 'LINC+ 5차년도 평가지원 및 유지보수',
                img: '2020/lincplus_01',
                type: '분석<i>/</i>설계<i>/</i>개발',
                description: '사회맞춤형 산학협력 선도대학(LINC+) 육성사업 5차년도 평가지원 및 유지보수',
                link: 'https://lincthree.nrf.re.kr/',
            },
            {
                id: 11,
                year: '2021.11 - 12',
                title: '부고로 사이트 개발',
                img: '2021/bugoro_01',
                type: '분석<i>/</i>설계<i>/</i>개발<i>/</i>디자인',
                description: '부고알림, 화환주문, LMS/카카오톡 메시지 전송, 관리자 모드, 반응형 웹사이트, Backend : Node.js/nestjs, Frontend : Vue/Quasar',
                link: 'https://부고로.com',
                details: [
                    {
                        id: 1,
                        img: '2021/bugoro_01',
                        title: '메인 페이지',
                        description: '',
                    },
                    {
                        id: 2,
                        img: '2021/bugoro_02',
                        title: '부고장',
                        description: '',
                    },
                    {
                        id: 3,
                        img: '2021/bugoro_03',
                        title: '상품상세 보기',
                        description: '',
                    },
                    {
                        id: 4,
                        img: '2021/bugoro_04',
                        title: '화환주문/결제',
                        description: '',
                    },
                    {
                        id: 5,
                        img: '2021/bugoro_05',
                        title: '관리자모드 - 부고장 관리',
                        description: '',
                    },
                    {
                        id: 6,
                        img: '2021/bugoro_06',
                        title: '관리자모드 - 주문 관리',
                        description: '',
                    },
                    {
                        id: 7,
                        img: '2021/bugoro_07',
                        title: '관리자모드 - 주문 현황',
                        description: '',
                    },
                    {
                        id: 8,
                        img: '2021/bugoro_08',
                        title: '상품관리',
                        description: '',
                    },
                ],
            },
            {
                id: 12,
                year: '2021.01 - 02',
                title: '대전금융복지상담센터 사이트 개발',
                img: '2022/gbsinbo_01',
                type: '분석<i>/</i>설계<i>/</i>개발<i>/</i>디자인',
                description: '센터소개, 상담예약신청, 사업소개, 고객센터, 관리자 모드, 반응형 웹사이트, Backend : Node.js/nestjs, Frontend : Vue/Quasar',
                link: 'https://www.gbsinbo.or.kr/',
                details: [
                    {
                        id: 1,
                        img: '2022/gbsinbo_01',
                        title: '메인 페이지',
                        description: '',
                    },
                    {
                        id: 2,
                        img: '2022/gbsinbo_02',
                        title: '센터소개',
                        description: '',
                    },
                    {
                        id: 3,
                        img: '2022/gbsinbo_03',
                        title: '사업소개',
                        description: '',
                    },
                    {
                        id: 4,
                        img: '2022/gbsinbo_04',
                        title: '관리자페이지',
                        description: '',
                    },
                ],
            },
            {
                id: 13,
                year: '2022.03 - 04',
                title: '질병관리청 보건의료인용 사이트 개발',
                img: '2022/hcp',
                type: '분석<i>/</i>설계<i>/</i>개발<i>/</i>디자인',
                description: '사이트, cms 관리자 모드, 반응형 웹사이트, eGovFrameWork',
                link: 'https://ncv.kdca.go.kr/hcp/page.do',
            },
            {
                id: 14,
                year: '2021.09 - 2022.09',
                title: '한국연구재단 선도대학/선도전문대학 육성사업(LINC 3.0) 평가지원 및 유지보수',
                img: '2020/lincplus_01',
                type: '분석<i>/</i>설계<i>/</i>개발',
                description: '산학연협력 선도대학/선도전문대학 육성사업(LINC 3.0) 1차년도 평가지원 및 유지보수',
                link: 'https://lincthree.nrf.re.kr/',
            },
            {
                id: 15,
                year: '2022.09 - 2022.09',
                title: '한국연구재단 3단계 산학연협력 선도대학 육성사업(LINC 3.0) 웹사이트 개편',
                img: '2022/lincthree',
                type: '분석<i>/</i>설계<i>/</i>개발<i>/</i>디자인',
                description: '반응형 웹사이트, Backend: SpringBoot, Frontend : Vue/Quasar',
                link: 'https://lincthree.nrf.re.kr/',
            },
            {
                id: 16,
                year: '2022.10 - 2022.12',
                title: '송도컨벤시아 웹사이트 개편',
                img: '2022/songdoconvensia',
                type: '분석<i>/</i>설계<i>/</i>개발<i>/</i>디자인',
                description: '반응형 웹사이트, 접근성, cms 관리자 모드, 개발 : eGovFrameWork 4, SpringBoot',
                link: 'https://songdoconvensia.visitincheon.or.kr/',
            },
        ],
        apps: [
            {
                id: 1,
                year: '2021.04',
                title: '운동타이머',
                img: '2021/healthtimer',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.hdstove.healthtimer&hl=ko',
            },
            {
                id: 2,
                year: '2021.04',
                title: '하루기록장',
                img: '2021/onedaydiary',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.hdstove.onedaydiary&hl=ko',
            },
            {
                id: 3,
                year: '2021.04',
                title: '충남대학교 식당메뉴',
                img: '2021/cnucafeteria',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.hdstove.cnucafeteria&hl=ko',
            },
            {
                id: 4,
                year: '2021.05',
                title: '지출 기록장',
                img: '2021/outgoingrecord',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.hdstove.outgoingrecord&hl=ko',
            },
            {
                id: 5,
                year: '2021.05',
                title: '레시피 재료비율 계산기',
                img: '2021/recipecalculator',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.hdstove.recipe.calculator&hl=ko',
            },
            {
                id: 6,
                year: '2021.05',
                title: '경조사비 기록장',
                img: '2021/eventInout',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.hdstove.event.inout&hl=ko',
            },
            {
                id: 7,
                year: '2021.05',
                title: '장바구니 메모',
                img: '2021/shoppinglist',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.hdstove.shoppinglist',
            },
            {
                id: 8,
                year: '2021.06',
                title: '나침반과 소음측정',
                img: '2021/compass',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.hdstove.compass',
            },
            {
                id: 9,
                year: '2021.06',
                title: '총무의 계산기 - 심플한 더치페이 계산기',
                img: '2021/dutchpay',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.hdstove.dutchpay',
            },
            {
                id: 10,
                year: '2021.06',
                title: '체중 기록장 - 다이어트 체중/식단 기록 관리',
                img: '2021/weight-diary',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.hdstove.weightdiary',
            },
            {
                id: 11,
                year: '2021.06',
                title: '물때정보',
                img: '2021/tide-time',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.hdstove.tidetime',
            },
            {
                id: 12,
                year: '2021.07',
                title: '이름 주소 영문변환',
                img: '2021/romanizer',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.hdstove.romanizer',
            },
            {
                id: 13,
                year: '2021.08',
                title: '전기요금 계산기',
                img: '2021/elecprice',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.hdstove.elecprice',
            },
            {
                id: 14,
                year: '2021.11',
                title: 'ETRI 감각대체 학습/시험 앱',
                img: '2021/enseption_00',
                type: '개발',
                details: [
                    {
                        id: 1,
                        img: '2021/enseption_01',
                        title: '메인 화면',
                        description: '',
                    },
                    {
                        id: 2,
                        img: '2021/enseption_02',
                        title: '카메라 이미지 학습',
                        description: '',
                    },
                    {
                        id: 3,
                        img: '2021/enseption_05',
                        title: '이미지 학습',
                        description: '',
                    },
                    {
                        id: 4,
                        img: '2021/enseption_06',
                        title: '이미지 학습',
                        description: '',
                    },
                ],
            },
            {
                id: 15,
                year: '2022.12',
                title: '송도컨벤시아',
                img: '2022/songdoconvensia-app',
                type: '기획<i>/</i>개발<i>/</i>디자인',
                link: 'https://play.google.com/store/apps/details?id=com.kr.smice&hl=ko&gl=US',
                link2: 'https://apps.apple.com/kr/app/%EC%86%A1%EB%8F%84%EC%BB%A8%EB%B2%A4%EC%8B%9C%EC%95%84/id1297935092',
            },
            {
                id: 16,
                year: '2023.06~',
                title: '충남대 전자출결앱 개선 및 유지보수',
                img: '2023/cnu-attend-01',
                type: '유지보수',
                link: 'https://play.google.com/store/apps/details?id=kr.ac.cnu.mobile.att',
                link2: 'https://apps.apple.com/kr/app/%EC%B6%A9%EB%82%A8%EB%8C%80%ED%95%99%EA%B5%90-%EC%A0%84%EC%9E%90%EC%B6%9C%EA%B2%B0/id1611307102?uo=4&at=11l6hc&app=itunes&ct=fnd',
            },
        ],
    },
    getters: {
        works(state) {
            return state.works.reverse();
        },
        apps(state) {
            return state.apps.reverse();
        },
    },
    mutations: {},
    actions: {},
    modules: {},
});
